// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './CardsHero.style';
import hero from 'images/WS_Cuenca_header_tarjetas_desktop.png';
import heroSmall from 'images/header_small.png';

function CardsHero(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const smallerDevices = useMediaQuery('(max-width:769px)');

    return (
        <Box className={classes.hero}>
            <img src={smallerDevices ? heroSmall : hero} alt="Tarjeta física Cuenca" />
            <Box className={classes.title}>
                <p>Una sola cuenta,</p>
                <p className={classes.bigger}>DOS</p>
                <p className={classes.bigger}>TARJETAS</p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(CardsHero);
