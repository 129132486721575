import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        hero: {
            fontFamily: 'MuseoSans, sans-serif',
            marginTop: '180px',
            height: '349px',
            width: '100%',
            marginBottom: '85px',
            '& img': {
                height: '150%',
                width: '100%',
                objectFit: 'cover',
            },
            [theme.breakpoints.up(1880)]: { height: '600px' },
            [theme.breakpoints.up(1700)]: { height: '500px' },
            [theme.breakpoints.up(1500)]: { height: '550px' },
            [theme.breakpoints.up(1300)]: { height: '400px' },
            [theme.breakpoints.down(900)]: {
                height: '300px',
                marginBottom: '56px',
            },
            [theme.breakpoints.down(769)]: {
                height: '100%',
                display: 'flex',
                marginBottom: '0px',
                flexDirection: 'column-reverse',
                '& img': {
                    height: '100%',
                },
            },
        },
        title: {
            fontSize: '34px',
            fontWeight: 500,
            position: 'absolute',
            right: '14vw',
            top: '30vh',
            color: '#fff',
            lineHeight: '41px',
            letterSpacing: '0.58px',
            // [theme.breakpoints.down(900)]: {
            //     top: '33vh',
            // },
            [theme.breakpoints.down(769)]: {
                position: 'initial',
                color: '#3D4274',
                alignSelf: 'center',
                marginBottom: '150px',
                marginTop: '0px !important',
                '& p': {
                    marginBottom: '0px',
                },
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '29px',
                marginBottom: '120px',
            },
            [theme.breakpoints.down(701)]: {
                marginLeft: '10%',
                marginTop: '2% !important',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '24px',
                lineHeight: '45px',
                marginTop: '4% !important',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '22px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '20px',
                lineHeight: '40px',
                marginLeft: '0%',
                marginBottom: '110px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '18px',
                marginBottom: '100px',
                lineHeight: '30px',
                marginLeft: '15%',
            },
        },
        bigger: {
            fontSize: '90px',
            fontWeight: 900,
            lineHeight: '108px',
            letterSpacing: '0.77px',
            [theme.breakpoints.down(769)]: {
                lineHeight: '100px',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '80px',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '70px',
                lineHeight: '95px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '50px',
                lineHeight: '75px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '45px',
                lineHeight: '65px',
            },
        },
    });
