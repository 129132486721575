// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import logoUnion from 'images/logos_union.svg';

import { styles } from './Alliance.style';

function Alliance(props: WithStyles<typeof styles>) {
    const { classes } = props;

    return (
        <Box className={classes.gridContainer}>
            <Box id="logosUnion">
                <img src={logoUnion} alt="TarjetasCuenca + MasterCard + Cuenca" />
            </Box>
            <Box className={classes.textContainer}>
                <p className="subtitle">Con esta alianza</p>
                <div className={classes.titleContainer}>
                    <p className="subtitle2">SALES </p>
                    <p className="subtitle3">GANANDO</p>
                </div>
                <p className="text">
                    Las tarjetas física y virtual son posibles gracias a la alianza con Tarjetas Cuenca y Mastercard, la
                    cual nos permite ofrecerte estos beneficios y muchos más.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Alliance);
