// React imports
import React, { useState } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Carousel from 'components/Carousel';
import GifCarousel from 'components/GifCarousel';

// local imports
import { styles } from './CardsCarousel.style';

function CardsCarousel(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const [isVirtual, setVirtual] = useState(true);
    let imagesData = {
        virtual: [
            style => <GifCarousel type="tarjeta_virtual_1" style={style} />,
            style => <GifCarousel type="tarjeta_virtual_2" style={style} />,
            style => <GifCarousel type="tarjeta_virtual_3" style={style} />,
            style => <GifCarousel type="tarjeta_virtual_4" style={style} />,
            style => <GifCarousel type="tarjeta_virtual_5" style={style} />,
            style => <GifCarousel type="tarjeta_virtual_6" style={style} />,
        ],
        fisical: [
            style => <GifCarousel type="tarjeta_fisica_1" style={style} />,
            style => <GifCarousel type="tarjeta_fisica_2" style={style} />,
            style => <GifCarousel type="tarjeta_fisica_3" style={style} />,
            style => <GifCarousel type="tarjeta_fisica_4" style={style} />,
            style => <GifCarousel type="tarjeta_fisica_5" style={style} />,
            style => <GifCarousel type="tarjeta_fisica_6" style={style} />,
        ],
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <p className={classes.subtitle}>TARJETA FÍSICA</p>
                <p className={classes.text}>
                    Una tarjeta física la cual puedes usar para pagar todas tus compras en México y en el extranjero
                    ¿Necesitas dinero en efectivo? Retíralo con tu tarjeta física en la red de cajeros.
                </p>
                <br />
                <p className={classes.subtitle}>TARJETA VIRTUAL</p>
                <p className={classes.text}>
                    Una tarjeta virtual que puedes usar para pagar todas tus compras en línea de forma segura, así como
                    tus servicios de streaming: Spotify, Uber, Rappi, Didi, Netflix, Amazon, Mercado Libre, PayPal, Play
                    Store; además podrás rentar autos, realizar reservaciones de hoteles y domiciliar pagos.
                </p>
            </Box>
            <Box className={classes.carousel}>
                {isVirtual && (
                    <Carousel dataPerPage={imagesData['virtual'].length} carouselSlides={imagesData['virtual']} />
                )}
                {!isVirtual && (
                    <Carousel dataPerPage={imagesData['fisical'].length} carouselSlides={imagesData['fisical']} />
                )}
                <Box className={classes.inline}>
                    <p className={isVirtual ? 'left' : 'active left'} onClick={() => setVirtual(false)}>
                        FÍSICA
                    </p>
                    <p className={isVirtual ? 'active left' : 'left'} onClick={() => setVirtual(true)}>
                        VIRTUAL
                    </p>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(CardsCarousel);
