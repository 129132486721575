// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { styles } from './CardsIntro.style';

function CardsIntro(props: WithStyles<typeof styles>) {
    const { classes, isFullLicense } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.textContainer}>
                <p className="subtitle">¡Un producto </p>
                <p className="subtitle2">FINANCIERO &nbsp;&nbsp;&nbsp;DIFERENTE!</p>
                <p className="text">
                    Dos tarjetas: una virtual y una física. Con Cuenca no hay saldo mínimo. Al abrir tu cuenta y
                    depositar $50 MXN o más obtienes una tarjeta virtual con la cual puedes pagar todas tus compras en
                    línea: pide el súper, ve tu serie favorita, domicilia pagos o consiéntete con tu postre favorito a
                    domicilio.
                </p>
            </Box>
            <Box
                className={classes.gridContainer}
                style={{
                    borderRadius: '1px',
                    borderTop: '1px',
                    borderLeft: '1px',
                    borderBottom: '1px',
                    borderRight: '0px',
                    borderColor: '#3D4274',
                    borderStyle: 'solid',
                    backgroundColor: '#ffffff',
                }}
            >
                <Box className={classes.textContainer1}>
                    <p className={classes.bigText}>2</p>
                    <p className="subtitle">TARJETAS,</p>
                    <p className="subtitle">UNA CUENTA,</p>
                    <p className="subtitle">TÚ TIENES EL</p>
                    <p className="subtitle">CONTROL</p>
                </Box>
                <Box className={classes.textContainer2}>
                    <p className="text">
                        Si deseas obtener una tarjeta física, escoge tu diseño favorito, deposita $100.00 MXN (costo del
                        envío, incluye IVA) y recíbela en casa.
                    </p>
                    <p className={classes.straightLine} />
                    <p className="text">
                        Con ella tienes acceso a la red de cajeros automáticos y podrás pagar tus compras en
                        establecimientos de México y el extranjero.
                    </p>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(CardsIntro);
