import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            [theme.breakpoints.down(769)]: {
                marginTop: '32%',
            },
        },
    });
