// React imports
import React, { useState, useEffect } from 'react';

// UI – style imports
import { WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

import dashTarjeta from 'images/web_Tarjetas_Cuenca.jpg';
import { styles } from './CardsBenefits.style';

function CardsBenefits(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const [showSafariMargin, setMargin] = useState(false);

    return (
        <Box className={clsx(classes.root, showSafariMargin && 'safari-margin')}>
            <Box id="phoneCard">
                <img src={dashTarjeta} alt="Tarjeta y app Cuenca" />
            </Box>
            <Box className={classes.textContainer}>
                <Box className={classes.titleContainer}>
                    <p id="topTitle">Los beneficios de</p>
                    <div id="title">
                        <p id="top">TUS TARJETAS</p>
                        <p id="bot">FÍSICA Y VIRTUAL</p>
                    </div>
                </Box>
                <p id="text">
                    Las tarjetas te ofrecen un producto diferente a los productos de débito con los que no puedes:
                    generar un historial crediticio positivo, rentar autos, reservar hoteles, ni recibir en minutos un
                    reembolso por retiro de efectivo en cajeros, con ellos pagas comisiones por apertura y anualidad,
                    tienes que mantener un saldo mínimo. Y no te ofrecen la posibilidad de acceder a Meses Sin Intereses
                    y una línea de crédito.
                </p>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(CardsBenefits);
