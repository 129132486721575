import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            marginBottom: '0px',
            marginTop: '250px',
            marginLeft: '30%',
            marginRight: '5%',
            [theme.breakpoints.down(769)]: {
                marginTop: '247px',
                marginLeft: '37.5%',
            },
            [theme.breakpoints.down(651)]: {
                marginTop: '180px',
                marginLeft: '32%',
            },
            [theme.breakpoints.down(551)]: {
                marginTop: '140px',
                marginLeft: '27%',
            },
            [theme.breakpoints.down(401)]: {
                marginTop: '0px',
            },
            [theme.breakpoints.down(351)]: {
                marginLeft: '22%',
            },
        },
        topContainer: {
            marginBottom: '112.84px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            [theme.breakpoints.down(769)]: {
                marginBottom: '65px',
                flexDirection: 'column',
            },
            [theme.breakpoints.down(751)]: {
                marginBottom: '60px',
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '55px',
            },
            [theme.breakpoints.down(551)]: {
                marginBottom: '50px',
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '45px',
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '40px',
            },
        },
        titleContainer: {
            fontSize: '32px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.89px',
            lineHeight: '50px',
            '& #subtitle1': {
                marginBottom: '0px',
                fontWeight: 900,
                fontSize: '36px',
            },
            [theme.breakpoints.down(769)]: {
                marginBottom: '110px',
                '& #subtitle1': {
                    lineHeight: '42px',
                },
            },
            [theme.breakpoints.down(751)]: {
                marginBottom: '90px',
                fontSize: '29px',
                '& #subtitle1': {
                    fontSize: '32px',
                    lineHeight: '40px',
                },
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '80px',
                fontSize: '26px',
                '& #subtitle1': {
                    fontSize: '29px',
                    lineHeight: '37px',
                },
            },
            [theme.breakpoints.down(601)]: {
                '& #subtitle1': {
                    lineHeight: '34px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& #subtitle1': {
                    lineHeight: '31px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '70px',
                fontSize: '22px',
                '& #subtitle1': {
                    fontSize: '25px',
                    lineHeight: '28px',
                },
            },
            [theme.breakpoints.down(451)]: {
                marginBottom: '60px',
                fontSize: '18px',
                '& #subtitle1': {
                    fontSize: '21px',
                    lineHeight: '25px',
                },
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '50px',
                fontSize: '16px',
                '& #subtitle1': {
                    fontSize: '19.43px',
                    lineHeight: '22.8px',
                },
            },
        },
        topText: {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            maxWidth: '53%',
            [theme.breakpoints.down(769)]: {
                maxWidth: '68%',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '16px',
                maxWidth: '72%',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '14px',
                lineHeight: '22px',
                maxWidth: '75%',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '12px',
                lineHeight: '20px',
                maxWidth: '80%',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '18px',
                maxWidth: '73%',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '10.86px',
                lineHeight: '16px',
                maxWidth: '80%',
            },
            [theme.breakpoints.down(351)]: {
                maxWidth: '95%',
            },
        },
        midContainer: {
            marginBottom: '60px',
            '& .header': {
                fontSize: '18.33px',
                fontWeight: 900,
                color: '#7DBCE7',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            [theme.breakpoints.down(769)]: {
                marginBottom: '99.6px',
            },
            [theme.breakpoints.down(751)]: {
                marginBottom: '85px',
                '& .header': {
                    fontSize: '16px',
                },
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '75px',
                '& .header': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                marginBottom: '65px',
                '& .header': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '55px',
                '& .header': {
                    fontSize: '11px',
                    lineHeight: '18px',
                },
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '40px',
                '& .header': {
                    fontSize: '10.9px',
                    lineHeight: '16px',
                },
            },
        },
        comision: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '10px',
            '& .comisionText': {
                fontSize: '18.33px',
                fontWeight: 500,
                color: '#3D4274',
                letterSpacing: '0.55px',
                marginBottom: '0',
                whiteSpace: 'nowrap',
                textOverflow: 'clip',
            },
            '& .greenText': {
                fontSize: '29.9px',
                fontWeight: 700,
                color: '#00A9A7',
                marginBottom: '0',
                marginRight: '10px',
                marginLeft: '10px',
            },
            [theme.breakpoints.down(769)]: {
                marginRight: '0px',
                '& .greenText': {
                    marginLeft: '20px',
                },
            },
            [theme.breakpoints.down(751)]: {
                '& .comisionText': {
                    fontSize: '16px',
                },
            },
            [theme.breakpoints.down(651)]: {
                '& .comisionText': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
                '& .greenText': {
                    fontSize: '29px',
                },
            },
            [theme.breakpoints.down(551)]: {
                '& .comisionText': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
                '& .greenText': {
                    fontSize: '28px',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& .comisionText': {
                    fontSize: '11px',
                    lineHeight: '18px',
                },
                '& .greenText': {
                    fontSize: '27px',
                },
            },
            [theme.breakpoints.down(401)]: {
                '& .comisionText': {
                    fontSize: '10.86px',
                    lineHeight: '16px',
                },
                '& .greenText': {
                    fontSize: '24px',
                    marginLeft: '6px',
                },
            },
            [theme.breakpoints.down(390)]: {
                '& .comisionText': {
                    lineHeight: '11px',
                },
                '& .greenText': {
                    fontSize: '14px',
                    marginLeft: '3px',
                },
            },
        },
        bottomText: {
            width: '35%',
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            [theme.breakpoints.down(769)]: {
                width: '65%',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '16px',
                width: '75%',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '14px',
                lineHeight: '22px',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '12px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '18px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '10.86px',
                lineHeight: '16px',
                width: '90%',
            },
        },
        rightNumber: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    });
