import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        gridContainer: {
            fontFamily: 'MuseoSans, sans-serif',
            marginBottom: '250px',
            marginTop: '250px',
            marginLeft: '30%',
            marginRight: '5%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& #logosUnion': {
                marginLeft: '20%',
                '& img': {
                    height: '100%',
                    width: '100%',
                },
            },
            [theme.breakpoints.down(769)]: {
                marginLeft: '0%',
                marginBottom: '120px',
                '& #logosUnion': {
                    marginLeft: '22%',
                    '& img': {
                        height: '74% !important',
                    },
                },
            },
            [theme.breakpoints.down(751)]: {
                marginTop: '220px',
                marginBottom: '85px',
                '& #logosUnion': {
                    '& img': {
                        height: '63% !important',
                    },
                },
            },
            [theme.breakpoints.down(701)]: {
                marginBottom: '0px',
            },
            [theme.breakpoints.down(651)]: {
                marginTop: '210px',
                '& #logosUnion': {
                    marginLeft: '20%',
                    '& img': {
                        height: '58% !important',
                    },
                },
            },
            [theme.breakpoints.down(601)]: {
                marginTop: '190px',
                '& #logosUnion': {
                    '& img': {
                        height: '53% !important',
                    },
                },
            },
            [theme.breakpoints.down(551)]: {
                marginTop: '170px',
                '& #logosUnion': {
                    marginLeft: '15%',
                    '& img': {
                        marginTop: '2%',
                        height: '45% !important',
                    },
                },
            },
            [theme.breakpoints.down(501)]: {
                marginTop: '160px',
                '& #logosUnion': {
                    '& img': {
                        marginTop: '5%',
                    },
                },
            },
            [theme.breakpoints.down(451)]: {
                '& #logosUnion': {
                    '& img': {
                        height: '40% !important',
                    },
                },
            },
            [theme.breakpoints.down(401)]: {
                marginTop: '150px',
            },
            [theme.breakpoints.down(370)]: {
                '& #logosUnion': {
                    marginLeft: '11%',
                },
            },
        },
        titleContainer: {
            marginTop: '12px',
            display: 'flex',
            [theme.breakpoints.down(1200)]: {
                display: 'block',
            },
        },
        textContainer: {
            width: '50%',
            fontSize: '32px',
            color: theme.palette.primary.main,
            letterSpacing: '0.89px',
            lineHeight: '50px',
            fontWeight: 500,
            marginBottom: '0px',
            '& .subtitle': {
                marginBottom: '0px',
                lineHeight: '30px',
            },
            '& .subtitle2': {
                fontSize: '36px',
                fontWeight: 900,
                marginBottom: '0px !important',
            },
            '& .subtitle3': {
                fontSize: '36px',
                fontWeight: 900,
                marginBottom: '90px',
                marginLeft: '10px',
            },
            '& .text': {
                fontSize: '18.3px',
                letterSpacing: '0.7px',
                lineHeight: '26.35px',
                width: '70%',
            },
            [theme.breakpoints.down(1200)]: {
                '& .subtitle3': {
                    marginLeft: '0px',
                },
            },
            [theme.breakpoints.down(769)]: {
                '& .subtitle3': {
                    marginBottom: '129px',
                    marginLeft: '10%',
                },
                '& .text': {
                    width: '78%',
                },
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '29px',
                '& .subtitle2': {
                    fontSize: '32px',
                },
                '& .subtitle3': {
                    fontSize: '32px',
                    marginBottom: '110px',
                },
                '& .text': {
                    fontSize: '16px',
                },
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '26px',
                '& .subtitle': {
                    lineHeight: '25px',
                },
                lineHeight: '40px',
                '& .subtitle2': {
                    fontSize: '29px',
                },
                '& .subtitle3': {
                    fontSize: '29px',
                    marginBottom: '100px',
                },
                '& .text': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '22px',
                lineHeight: '30px',
                '& .subtitle': {
                    lineHeight: '20px',
                },
                '& .subtitle2': {
                    fontSize: '25px',
                },
                '& .subtitle3': {
                    fontSize: '25px',
                    marginBottom: '90px',
                },
                '& .text': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '20px',
                '& .subtitle2': {
                    fontSize: '23px',
                },
                '& .subtitle': {
                    lineHeight: '16px',
                },
                '& .subtitle3': {
                    fontSize: '23px',
                    marginBottom: '80px',
                },
                '& .text': {
                    fontSize: '11px',
                    lineHeight: '18px',
                },
            },
            [theme.breakpoints.down(451)]: {
                fontSize: '18px',
                '& .subtitle2': {
                    fontSize: '21px',
                },
                '& .subtitle3': {
                    fontSize: '21px',
                    marginBottom: '70px',
                },
                '& .text': {
                    width: '88%',
                },
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '16px',
                lineHeight: '22px',
                '& .subtitle2': {
                    fontSize: '19.43px',
                    lineHeight: '22.8px',
                },
                '& .subtitle3': {
                    fontSize: '19.43px',
                    lineHeight: '22.8px',
                    marginBottom: '66px',
                },
                '& .text': {
                    fontSize: '10.86px',
                    lineHeight: '16px',
                },
            },
            [theme.breakpoints.down(371)]: {
                letterSpacing: '.7px',
            },
        },
    });
