import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            marginBottom: '250px',
            marginTop: '93px',
            marginLeft: '30%',
            marginRight: '5%',
            [theme.breakpoints.down(769)]: {
                marginBottom: '0px',
                marginTop: '185px',
                marginLeft: '22%',
            },
            [theme.breakpoints.down(751)]: {
                marginTop: '160px',
            },
            [theme.breakpoints.down(651)]: {
                marginTop: '130px',
            },
            [theme.breakpoints.down(551)]: {
                marginTop: '100px',
            },
            [theme.breakpoints.down(501)]: {
                marginTop: '80px',
            },
            [theme.breakpoints.down(401)]: {
                marginTop: '60px',
            },
        },
        topContainer: {
            marginBottom: '60px',
            '& .header': {
                fontSize: '18.33px',
                fontWeight: 900,
                color: '#7DBCE7',
                letterSpacing: '2px',
                marginBottom: '0',
            },
            [theme.breakpoints.down(769)]: {
                marginBottom: '78px',
                maxWidth: '70%',
                marginLeft: '22%',
                '& .header': {
                    letterSpacing: '.7px',
                },
            },
            [theme.breakpoints.down(751)]: {
                '& .header': {
                    fontSize: '16px',
                },
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '70px',
                marginLeft: '13%',
                '& .header': {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
            [theme.breakpoints.down(551)]: {
                marginLeft: '7%',
                marginBottom: '60px',
                '& .header': {
                    fontSize: '12px',
                    lineHeight: '20px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '50px',
                maxWidth: '80%',
                '& .header': {
                    fontSize: '11px',
                    lineHeight: '18px',
                },
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '40px',
                maxWidth: '100%',
                marginLeft: '5%',
                '& .header': {
                    fontSize: '10.83px',
                    lineHeight: '16px',
                },
            },
            [theme.breakpoints.down(351)]: {
                marginLeft: '0%',
            },
        },
        textContainer: {
            [theme.breakpoints.down(769)]: {
                marginLeft: '22%',
                marginBottom: '120px',
            },
            [theme.breakpoints.down(651)]: {
                marginBottom: '110px',
                marginLeft: '13%',
            },
            [theme.breakpoints.down(551)]: {
                marginLeft: '7%',
                marginBottom: '90px',
            },
            [theme.breakpoints.down(501)]: {
                marginBottom: '70px',
            },
            [theme.breakpoints.down(401)]: {
                marginBottom: '50px',
                marginLeft: '5%',
            },
            [theme.breakpoints.down(351)]: {
                marginLeft: '0%',
            },
        },
        container: {
            [theme.breakpoints.down(769)]: {
                display: 'flex',
                flexDirection: 'column',
            },
        },

        topText: {
            fontSize: '18.33px',
            fontWeight: 500,
            color: '#3D4274',
            letterSpacing: '0.75px',
            lineHeight: '26.35px',
            maxWidth: '58%',
            [theme.breakpoints.down(769)]: {
                maxWidth: '75%',
            },
            [theme.breakpoints.down(751)]: {
                fontSize: '16px',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '14px',
                lineHeight: '22px',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '12px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '18px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '10.83px',
                lineHeight: '16px',
                maxWidth: '88%',
            },
        },
        tableRow: {
            fontSize: '18.34px',
            fontWeight: 500,
            color: '#3F4375',
            letterSpacing: '0.5px',
            fontFamily: 'MuseoSans, sans-serif',
            borderBottom: '1px solid',
            paddingTop: '8.5px',
            paddingBottom: '13px',
            [theme.breakpoints.down(751)]: {
                fontSize: '16px',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '14px',
                lineHeight: '22px',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '12px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '18px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '10.86px',
            },
        },
        tableHeader: {
            fontSize: '17.19px',
            fontWeight: 900,
            color: '#3D4274',
            letterSpacing: '0.11px',
            lineHeight: '22px',
            padding: '6px 24px 16px 16px',
            borderBottom: '2px solid #3D4274',
            fontFamily: 'MuseoSans, sans-serif',
            [theme.breakpoints.down(751)]: {
                fontSize: '16px',
            },
            [theme.breakpoints.down(651)]: {
                fontSize: '14px',
            },
            [theme.breakpoints.down(551)]: {
                fontSize: '12px',
                lineHeight: '20px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '11px',
                lineHeight: '18px',
            },
            [theme.breakpoints.down(401)]: {
                fontSize: '10.86px',
                lineHeight: '16px',
            },
        },
    });
